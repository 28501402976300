import convexHull from 'monotone-convex-hull-2d'
import { getContrastColor as a11yGetContrastColor } from 'a11y-contrast-color'

interface QualityFactor {
  label: string;
  scaleFactor: number;
}

const concatenateClusterSummaries = async (
  data: any[], 
  hdbscanLabels: number[],
  qualityFactors?: QualityFactor[]
): Promise<string[]> => {
  const clusterSummaries: { [key: number]: string[] } = {};

  // Group summaries by cluster
  data.forEach((item, index) => {
    const clusterLabel = hdbscanLabels[index];
    if (clusterLabel !== -1) {
      if (!clusterSummaries[clusterLabel]) {
        clusterSummaries[clusterLabel] = [];
      }
      // Add the description if it exists
      if (item.description) {
        clusterSummaries[clusterLabel].push(
          `${item.name}: ${item.description}`
        );
      }
    }
  });

  // Wait for all summaries to resolve
  const summaryPromises = Object.entries(clusterSummaries).map(async ([cluster, summaries]) => {
    const summaryText = summaries.join('\n');
    // Rough estimation of token count (approx. 4 chars per token)
    const estimatedTokens = summaryText.length / 4;
    
    if (estimatedTokens > 1000) {
      return `These plants don't share a clear characteristic that sets them apart from other groups. Instead, they differ in many ways, which is why they've been grouped together.`;
    }

    const summaryKey = `cluster-summary-${cluster}-${summaries.join('').slice(0, 50)}`;
    
    // Create the quality factors part of the prompt
    let prompt = '';
    if (qualityFactors?.length) {
      prompt = `Consider that the following factors are currently weighted in importance (0-1 scale):
        ${qualityFactors.map(q => `${q.label}: ${(q.scaleFactor).toFixed(2)}`).join(', ')}.
        Are these plants grouped primarily because of their similarities in any of these factors? `;
    }

    prompt += `As a nursery manager, carefully examine this group of plants and in one short terse sentence without any introduction or context, state what these plants have in common: ${summaryText}. 
    State the mechanism directly and succinctly. You are speaking to a landscape architect who is trying to understand what plants to choose for a project.`;

    try {
      const { data: chatResponse } = await useAsyncData(
        summaryKey,
        () => $fetch('/api/ai/grq', {
          method: 'POST',
          body: { prompt }
        }),
        {
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null;
          }
        }
      );

      // Ensure we're getting the actual response text
      const responseText = chatResponse?.value?.text || '';
      
      // Clean up the response text
      const cleanedResponse = responseText
        .replace(/^(these plants |the plants |plants |this group of plants )/i, '')
        .trim();

      return cleanedResponse || `Cluster ${cluster}: Unable to generate summary`;
    } catch (error) {
      console.error('Error generating summary:', error);
      return `Cluster ${cluster}: Unable to generate summary`;
    }
  });

  // Wait for all promises to resolve
  return Promise.all(summaryPromises);
};

export const useDataUtils = () => {
  const speciesColors = [
    '#3b82f6', '#10b981', '#f59e0b', '#8b5cf6', '#ec4899',
    '#14b8a6', '#f97316', '#6366f1', '#84cc16', '#06b6d4',
    '#a855f7', '#ef4444', '#22c55e', '#eab308', '#64748b',
    '#0ea5e9', '#d946ef', '#14b8a6', '#f43f5e', '#8b5cf6',
    '#1e40af', '#065f46', '#92400e', '#5b21b6', '#9d174d',
    '#115e59', '#93c5fd', '#6ee7b7', '#fcd34d', '#c4b5fd',
    '#f9a8d4', '#5eead4',
  ];
  
  const mapSpeciesToColors = (species: string[]): { [key: string]: string } => {
    return species.reduce((acc, speciesName, index) => {
      acc[speciesName] = speciesColors[index % speciesColors.length];
      return acc;
    }, {});
  };
  
  const calculateClusterHulls = (data: number[][], hdbscanLabels: number[], absolutePadding: number = 0.1) => {
    if (!hdbscanLabels || hdbscanLabels.length === 0) {
      return {}
    }

    const clusters: { [key: number]: number[][] } = {}
    const hulls: { [key: number]: number[][] } = {}

    data.forEach((point, index) => {
      const label = hdbscanLabels[index]
      if (label !== -1) {
        if (!clusters[label]) {
          clusters[label] = []
        }
        clusters[label].push(point)
      }
    })

    for (const [label, points] of Object.entries(clusters)) {
      const hullIndices = convexHull(points)
      const hullPoints = hullIndices.map(i => points[i])
      
      const bbox = hullPoints.reduce(
        (acc, point) => ({
          minX: Math.min(acc.minX, point[0]),
          minY: Math.min(acc.minY, point[1]),
          maxX: Math.max(acc.maxX, point[0]),
          maxY: Math.max(acc.maxY, point[1]),
        }),
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
      )

      const paddedHull = hullPoints.map(point => [
        point[0] + (point[0] < (bbox.minX + bbox.maxX) / 2 ? -absolutePadding : absolutePadding),
        point[1] + (point[1] < (bbox.minY + bbox.maxY) / 2 ? -absolutePadding : absolutePadding)
      ])

      paddedHull.push(paddedHull[0])
      hulls[Number(label)] = paddedHull
    }

    // Return hulls and their colors
    return { 
      hulls, 
      clusterColors: Object.fromEntries(Object.keys(hulls).map(label => [label, speciesColors[Number(label) % speciesColors.length]]))
    }
  }

  const getContrastColor = (backgroundColor: string): string => {
    if (!backgroundColor) {
      return '#000000'; // Default to black if no background color is provided
    }

    // Convert hex to RGB
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Use a11y-contrast-color library
    const contrastColor = a11yGetContrastColor([r, g, b], 4.5); // Using WCAG AA standard for normal text

    if (contrastColor) {
      return `rgb(${contrastColor[0]}, ${contrastColor[1]}, ${contrastColor[2]})`;
    } else {
      // Fallback to the original logic if no suitable color is found
      return (r * 299 + g * 587 + b * 114) / 1000 > 128 ? '#000000' : '#FFFFFF';
    }
  };


  const contentTypeToExtension = {
    "application/pdf": "pdf",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/vnd.ms-powerpoint": "ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx"
  };

  const contentTypeToLabel = {
    "application/pdf": {
      label: "PDF",
      iconLarge: "vscode-icons:file-type-pdf2",
      iconSmall: "ph:file-pdf-fill",
    },
    "application/msword": {
      label: "Word Document",
      iconLarge: "vscode-icons:file-type-word",
      iconSmall: "",
    },
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
      label: "Word Document",
      iconLarge: "vscode-icons:file-type-word",
      iconSmall: "",
    },
    "application/vnd.ms-excel": {
      label: "Excel Document",
      iconLarge: "vscode-icons:file-type-excel",
      iconSmall: "",
    },
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
      label: "Excel Document",
      iconLarge: "vscode-icons:file-type-powerpoint",
      iconSmall: "",
    },
    "application/vnd.ms-powerpoint": {
      label: "PowerPoint",
      iconLarge: "vscode-icons:file-type-powerpoint",
      iconSmall: "",
    },
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
      label: "PowerPoint",
      iconLarge: "vscode-icons:file-type-powerpoint",
      iconSmall: "",
    },
    "text/plain": {
      label: "Text file",
      iconLarge: "hugeicons:txt-02",
      iconSmall: "",
    },
  }

  return {
    mapSpeciesToColors,
    concatenateClusterSummaries,
    calculateClusterHulls,
    getContrastColor,
    contentTypeToExtension,
    contentTypeToLabel,
  };
};
